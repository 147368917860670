import _atob from "./lib/atob";
import _btoa from "./lib/btoa";
var exports = {};
const atob = _atob;
const btoa = _btoa;
exports = {
  atob,
  btoa
};
export default exports;
const _atob2 = exports.atob,
      _btoa2 = exports.btoa;
export { _atob2 as atob, _btoa2 as btoa };